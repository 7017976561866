import React, { useEffect, useState } from "react";

import Doctor from "../Assets/about-pic.jpg";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  const [isVisible, setIsVisible] = useState(false);

  const observeSection = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3, // Adjust this threshold as needed
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once section is in view
        }
      });
    }, options);
  
    const target = document.querySelector('.about-image1', '.about-text-content');
    if (target) {
      observer.observe(target);
    }
  };

   
  useEffect(() => {
    observeSection();
  }, []);

  return (
    <div className="about-section">
      <div className="about-image-content">
        <img src={Doctor}className={`about-image1 ${isVisible ? 'animate' : ''}`}/>
      </div>

      <div  className={`about-text-content ${isVisible ? 'animated' : ''}`}>
        <h3 className="about-title" >
          <span>Who We Are</span>
        </h3>
        <p className="about-description">
        Meta Resources Group provides businesses with expert delivery teams, solutions architecture, IT staffing and training, including onshore and offshore solutions. Our passionate and purposeful team solves complex business problems by combining cutting-edge digital solutions with customized client delivery processes. We will work with you to expand your market share. grow revenue or minimize costs. 
        </p>

      </div>
    </div>
  );
}

export default About;
