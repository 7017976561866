import React from "react";
import { BrowserRouter as Router, Routes, Route, isRouteErrorResponse } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import ConsultingServices from "./Pages/ConsultingServices";
import Staffing from "./Pages/Staffing";
import Offshore from "./Pages/Offshore";
import TermsCond from "./Components/TermsCond";
import Preloader from "./Components/Preloader";
import Services from "./Pages/Services";

function App() {
  return (
    <div className="App">
        <Preloader />
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/terms" element={<TermsCond />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/consultingservices" element={<ConsultingServices />} />
          <Route path="/staffing" element={<Staffing />} />
          <Route path="/offshore" element={<Offshore />} />
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

